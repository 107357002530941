<template>
  <div class="container">This is the main page. Comming soon ...</div>
</template>
<script>
import { mapGetters } from "vuex";
import routerItems from "../constants/routerItems";
export default {
  name: "Main",
  mounted() {
    if (!this.currentProfile.ticketCode) {
      this.$router.replace({ name: routerItems.TICKET_CODE });
    }
  },
  computed: {
    ...mapGetters({
      currentProfile: "currentProfile",
    }),
  },
};
</script>
